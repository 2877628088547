<header [ngStyle]="{
  'background-image': getRoute(activeRoute).image ? 'url('+getRoute(activeRoute).image+')' : 'unset',
  'height': getRoute(activeRoute).image ?  (getRoute(activeRoute)?.height ?? null) : '75px',
  'background-position': getRoute(activeRoute).position ?? null
 }">
  <mat-toolbar [ngClass]="{'active': isActive()}">
    <app-website-title style="margin-right: 1em;"></app-website-title>
    <nav>

      @for(rout of getRoutes() ; track $index){
        @if(getRoute(rout).display ?? false){
          <a [href]="rout" class="tab-nav" [ngClass]="{'active':activeRoute.includes(rout)}">
            {{getRoute(rout).display}}
          </a>
        }
      }
      <div [ngStyle]="{'display': isLogged() ? 'none':'flex'}" style="gap: 0.5em;">
        <button type="button"  (click)="openLogin($event)" class="login-register">
          Login
        </button>
        <div class="spliter-wrapper">
          <div class="spliter"></div>
        </div>
        <button type="button" (click)="openRegister($event)" class="login-register">
          Register
        </button>
      </div>
      @if(isLogged()){
        <app-user-icon [isActive]="isActive()"></app-user-icon>
      }
    </nav>
    <div class="button-nav">
      <button type="button" class="button-menu-header" [matMenuTriggerFor]="menu" >
        <mat-icon>menu</mat-icon>
      </button>
    </div>
  </mat-toolbar>
  <mat-menu #menu="matMenu"  class="nav-oculta" backdropClass="cdk-header">
    @for(rout of getRoutes() ; track $index){
      @if(getRoute(rout).display ?? false){
        <a [href]="rout" class="tab-nav" [ngClass]="{'active':activeRoute.includes(rout)}">
          {{getRoute(rout).display}}
        </a>
      }
    }
    <div style="margin-bottom: 0.5rem; display: flex; justify-content: center;align-items: center; flex-direction: row;">
      @if(!isLogged()){
        <button type="button" (click)="openLogin($event)" class="login-register">
          Login
        </button>
        <div class="spliter-wrapper">
          <div class="spliter"></div>
        </div>
        <button type="button" (click)="openRegister($event)" class="login-register">
          Register
        </button>
      }@else {
        <app-user-icon [isActive]="true" [hover]="false"></app-user-icon>
      }
    </div>
  </mat-menu>
  @if(!activeRoute || activeRoute == '/' || activeRoute=='/rent-rooms-dublin'){
    <article class="search-home">

      @if(activeRoute != '/rent-rooms-dublin'){
        <h2 style="font-family: Source Sans Pro; font-weight: 700;">FIND A PLACE TO FEEL LIKE HOME</h2>
        <h3 style="font-family: Montserrat;">Accommodations for modern people in Dublin</h3>
      }
      <section [ngStyle]="{'width': '90%', 'max-width': activeRoute == '/rent-rooms-dublin' ? null : '1204px'}" style="display: flex; flex-wrap: wrap; gap: 1em; justify-content: center;">
        
        <app-calendar [startDate]="start" [endDate]="end"></app-calendar>

        <div style="display: flex; justify-content: center; align-items: center; gap: 1em; height: 55px; position: relative;">
          @if(activeRoute == '/rent-rooms-dublin'){
            <button aria-label="Filters button" type="button" (click)="onFilters = !onFilters" style="width: 60px; cursor: pointer;  height: 100%; border-radius: 4px; outline: none; border: none; box-shadow: 3px 5px 7px -1px rgba(0,0,0,0.5);">
              <mat-icon>
                tune
              </mat-icon>
            </button>

            <app-filters-find-home (close)="onFilters = !$event" [ngClass]="{'hidden':!onFilters}" class="menu-filters"></app-filters-find-home>
          }
          <button (click)="search()" type="button" style="cursor: pointer; height: 100%; padding-left: 2rem;  padding-right: 2rem; font-weight: 600; box-shadow: 3px 5px 7px -1px rgba(0,0,0,0.5);
          font-size: 1rem;  color: white; outline: none; border: none; border-radius: 4px; background-color: #db332a; font-family: Montserrat;">
            Search
          </button>
        </div>
      </section>
    </article>
  }
</header>
