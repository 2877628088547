import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { WebsiteTitleComponent } from "../../../components/website-title/website-title.component";
import { UserIconComponent } from "../../../components/user-icon/user-icon.component";

@Component({
  selector: 'app-header-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    WebsiteTitleComponent,
    UserIconComponent
],
  templateUrl: './header-dashboard.component.html',
  styleUrls: ['./header-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderDashboardComponent {

  @Output() toggle: EventEmitter<any> = new EventEmitter();


  toggleMenu(){
    this.toggle.emit(true);
  }
}
