<div style="display: flex; flex-direction: row; background-color: white;">

  <section style="width: 510px; background-position: center center; background-size: cover; background-repeat: no-repeat; background-image: url('/assets/images/home/my-other-nest-home-30.jpg');">

  </section>
  <form [formGroup]="formLogin" method="post" (submit)="login()" style="display: flex; flex-direction: column; width: 100%;">
    <div class="title-modal" mat-dialog-title>
      <h4>LOGIN</h4>
      <h3 style="margin-bottom: 0.5em;">WELCOME BACK</h3>
      <mat-progress-bar  [mode]="loading ? 'indeterminate' : 'determinate'"></mat-progress-bar>
      <button mat-dialog-close type="button" title="Close modal" class="close-button" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <mat-dialog-content>

      <mat-form-field style="width: 100%;">
        <mat-label>Email</mat-label>
        <input formControlName="email" name="email"
        autocomplete="username email"
        type="email" matInput  />
        @if(formLogin.get('email')?.hasError){
          <mat-error style="font-family: Montserrat;">
            {{getError('email')}}
          </mat-error>
        }
      </mat-form-field>
      <mat-form-field style="width: 100%;">
        <mat-label>Password</mat-label>
        <input  formControlName="password"
        autocomplete="current-password"
        name="password" type="password" matInput  #psw />
        @if(psw.type == 'password'){
          <mat-icon style="cursor: pointer;" (click)="psw.type = 'text' "  matIconSuffix>visibility_off</mat-icon>
        }@else {
          <mat-icon style="cursor: pointer;" (click)="psw.type = 'password' "  matIconSuffix>visibility</mat-icon>
        }

        @if(formLogin.get('password')?.hasError){
          <mat-error style="font-family: Montserrat;">
            {{getError('password')}}
          </mat-error>
        }
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
      <div style="display: flex; flex-wrap: wrap; gap: 1em; justify-content: space-between; align-items: center; width: 100%; margin-bottom: 1rem; color: #415273;">
        
        <div style="display: flex; flex-wrap: wrap; gap: 1em;">
          <a [mat-dialog-close]="{forgotPassword: true}" style="text-decoration: none; color: #db332a; cursor: pointer; font-family: Montserrat;">
            Forgot password?
          </a>
        </div>
      </div>
      <button [disabled]="loading" class="login-button" type="submit" mat-button style="font-family: Montserrat;">Log In</button>
      <p style="color: #415273; margin-bottom: 0;font-family: Montserrat; margin-top: 1rem; width: 100%; text-align: center;">Don't you have an account? <a [mat-dialog-close]="{register: true}" style="color: #db332a; text-decoration: none; cursor: pointer;font-family: Montserrat;">Register</a></p>
    </mat-dialog-actions>
  </form>


</div>
