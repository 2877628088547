import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ApiService } from '../../../core/services/api.service';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AuthService } from '../../../core/services/auth.service';
import { HttpHeaders } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { SnackbarService } from '../../../core/services/snackbar.service';
import { Rent } from '../../../core/classes/rent';
import { RequestManager } from '../../../core/managers/RequestManager';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    FormsModule,ReactiveFormsModule, MatFormFieldModule,MatInputModule,MatButtonModule,
    CommonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatCheckboxModule,
    MatIconModule,
    MatProgressBarModule,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {

  formLogin : FormGroup = new FormGroup({
    email : new FormControl(null, [Validators.required]),
    password: new FormControl(null,[Validators.required])
  })

  loading : boolean = false;
  errorLogin : string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) private data : any,
    public dialogRef: MatDialogRef<LoginComponent>,
    private snackbar : SnackbarService,
    private requestManager : RequestManager,
    private api : ApiService, private auth : AuthService){
      
  }

  getError(control: string) {
    let form : FormGroup = this.formLogin;
    const formControl : any = form.get(control)!;

    const errors : any = formControl.errors;
    if(errors?.required){
      return 'This field is required.';
    }
    if(errors?.[control] ?? false){
      return errors[control];
    }
    return '';
  }

  async login() {
    if(this.formLogin.invalid) return;

    this.loading = true;
    const body = this.formLogin.getRawValue();

    const method : string = "auth/login";

    try{
      const response = await firstValueFrom(this.api.post({body,method}));
      const result = response;
      this.loading = false;
      if(result){
        const data = result.data;
        const token = result.token;
        this.auth.login(data,token, this.data.request ? false :true);
        this.snackbar.close()
        this.dialogRef.close({logged : true});
        this.proceedToRequest(this.data.request);
      }
    }catch(error){
      if(error == 'ACCOUNT_NOT_ACTIVE'){
          this.dialogRef.close({confirm_mode: true,body: body})
      }else if(error == 'NOT_FOUND'){
        this.snackbar.text('Email or password incorrect','error', false);
      }
      this.loading = false;
    }

  }



  proceedToRequest(rent : Rent){
    if(rent.isRequestingLoading()) return;

    rent.requesting(true);

    const body : any = {
      arrive : rent.getArrive()!,
      depart : rent.getDepart()!,
      about : rent.getAbout(),
    }
    this.requestManager.apply(rent.getRentRoom().id,body, {
      next: (result) => {
        this.snackbar.text(this.requestManager.MESSAGE_REQUEST_SENT,'ok',8);
        rent.requested(result);
        this.auth.forceUpadte();
      },
      error : (result) => {
        this.snackbar.text('This date is currently unavailable for booking','warn',8);
      },
      finalize: () => {
        rent.requesting(false);
      }
    })
  }

}
