import { IHeaderRoutes } from "../interfaces/header-routes.interface";




export const HEADER_ROUTES : IHeaderRoutes =  {

    '/rent-rooms-dublin': {
      image : "/assets/images/rent-rooms-dublin/my-other-nest-home-12.jpg",
      display : 'Find a home',
      height : '400px',
      position : 'top'
    },
    '/how-we-rent-rooms-in-dublin': {
      image : "/assets/images/how-we-rent-rooms-in-dublin/my-other-nest-home-4.jpg",
      display : 'How it works',
      height : '400px',
      position : 'top'
    },
    '/landlord-area': {
      image: "/assets/images/landlord-area/my-other-nest-home-24.jpg",
      height : '400px',
      position : 'bottom'
    },
    '/website-rooms-for-rent-dublin':{
      image: "/assets/images/website-rooms-for-rent-dublin/my-other-nest-home-27.jpg",
      display: "About us",
      height : '400px',
      position : '100%'
    },

    '/roommates-nesters-experiences':{
      image : "/assets/images/roommates-nesters-experiences/my-other-nest-home-22.jpg",
      height : '400px',
      position : '100%'
    },
    
    '/platform-rooms-rent-dublin': {
      image : "/assets/images/platform-rooms-rent-dublin/my-other-nest-home-29.jpg",
      position: "66% center",
      height : '400px'
    },

    '/contacts-room-rental-platform-in-dublin':{
      image : "/assets/images/contacts-room-rental-platform-in-dublin/my-other-nest-home-16.jpg",
      height : '400px',
      position : '100%'
    },
    '/faqs': {
      image : null,
    },
    '/terms-and-conditions': {
      image : null,
    }

  }
