import { CommonModule, isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostListener, Inject, Input, Output, PLATFORM_ID, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDrawer, MatDrawerMode, MatSidenavModule } from '@angular/material/sidenav';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { AuthService } from '../../../core/services/auth.service';

@Component({
  selector: 'app-aside-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    MatSidenavModule,
    MatButtonModule,MatIconModule,
    RouterModule

  ],
  templateUrl: './aside-dashboard.component.html',
  styleUrls: ['./aside-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AsideDashboardComponent {

  @Input() opened : boolean = true;
  @Output() toggle :  EventEmitter<any> = new EventEmitter();

  urlActive : string = "";
  mode : MatDrawerMode = "side";

  @ViewChild(MatDrawer) drawer !: MatDrawer

  constructor(private  router: Router, @Inject(PLATFORM_ID) private platformId: Object,private auth : AuthService, private changes : ChangeDetectorRef){
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.urlActive = event.urlAfterRedirects;
      }
    });
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.setScreenType(window.innerWidth);
      this.firstmP = false;
    }
  }
  clickedOpt(){
    if(this.mode == "side") return;

    this.drawer.close();
  }

  firstmP : boolean = true;
  ngAfterViewInit(){
    if(this.mode == "side"){
      this.opened = true;
      this.drawer.open();
      
      this.changes.detectChanges();
    }else{
      this.opened = false;
    }
   
  }

  getDashboard(){
    return this.auth.getDashboard();
  }

  close(){
    this.opened = false;
  }

  toggleMenu(){
    this.opened = !this.opened;
    if(this.opened){
      this.drawer.open();
    }else{
      this.drawer.close();
    }
  }


  @HostListener('window:resize', ['$event'])
  onResize(event?: Event): void {
    // Obtener el ancho actual de la ventana
    const screenWidth = window.innerWidth;
    
    // Determinar el tipo de pantalla
    this.setScreenType(screenWidth);
  }

  private setScreenType(width: number): void {
   
    /*if (width < 576) {
      
    } else*/ if (width < 768) {
      this.mode = "over";
    // } else if (width < 992) {
      
    // } else if (width < 1200) {
     
    } else {
     this.mode = "side";
    }
  }

}
