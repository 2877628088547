import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Inject, OnDestroy, PLATFORM_ID } from '@angular/core';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatTabsModule} from '@angular/material/tabs';
import { WebsiteTitleComponent } from "../../components/website-title/website-title.component";
import {MatIconModule} from '@angular/material/icon';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import {MatMenuModule, MatMenuTrigger} from '@angular/material/menu';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { filter } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import * as _moment from 'moment';
import {MatSelectModule} from '@angular/material/select';
import {default as _rollupMoment} from 'moment';
import {provideMomentDateAdapter} from '@angular/material-moment-adapter';
import { FiltersFindHomeComponent } from '../../modules/find-a-home/filters-find-home/filters-find-home.component';
import { HeaderService } from '../../core/services/header.service';
import { HEADER_ROUTES } from '../../data/const/header-routes.routes';
import { FORMAT_DATE, GLOBAL_FORMAT } from '../../data/const/format-date';
import { AuthService } from '../../core/services/auth.service';
import { FilterFindHomeService } from '../../core/services/filter-find-home.service';
import { UserIconComponent } from '../../components/user-icon/user-icon.component';
import { CalendarComponent } from '../../components/calendar/calendar.component';
import moment from 'moment';

@Component({
  selector: 'app-header',
  standalone: true,
  providers: [provideMomentDateAdapter(FORMAT_DATE)],
  imports: [MatToolbarModule, MatButtonModule,
    MatMenuModule,
     MatTabsModule, WebsiteTitleComponent,MatIconModule, CommonModule, RouterModule,
    MatDatepickerModule,
    FormsModule,ReactiveFormsModule, MatFormFieldModule, MatInputModule,
    MatSelectModule, FiltersFindHomeComponent, UserIconComponent, CalendarComponent],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnDestroy{


  private _is_active : boolean = false;
  private blockHeader : boolean = false;

  public activeRoute : string = "";
  private wasOpen : boolean = false;

  onFilters : boolean = false;
  start : FormControl = new FormControl(null);
  end : FormControl = new FormControl(null);

  default_image = "/assets/images/home/my-other-nest-home-6.jpg";

  private _blockmenu : boolean = false;

  profileImage ?: string|null ;
  private routes : any = HEADER_ROUTES;
  _susbsToDelete : any[] = [];
  constructor(private _router : Router,
    private filtersService : FilterFindHomeService,
    @Inject(PLATFORM_ID) protected platformId: Object,
    private _headerService: HeaderService, private changes : ChangeDetectorRef,  private dialog : MatDialog,
    public auth : AuthService,)
  {

    this.activeRoute = this._router.url.split('?')[0];
    if(isPlatformBrowser(this.platformId)){
      this.toggleActivate();

      const sub = this._router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.activeRoute = event.urlAfterRedirects.split('?')[0];
        
        this.changes.detectChanges();
        if(this.activeRoute.startsWith('/how-we-rent-rooms-in-dublin')){
          this.activeRoute = '/how-we-rent-rooms-in-dublin';
        }

        const route = this.getRoute(this.activeRoute);
        if(route.image === null){
          this._is_active = true;
          this.blockHeader = true;
        }

      });
    }
    
  }


  openMenu(menuTrigger: MatMenuTrigger) {

    menuTrigger.openMenu();
    this._blockmenu = false;
  }

  blockMenuTop(){
    this._blockmenu = true;
  }

  closeMenu(menuTrigger : MatMenuTrigger,level:number = 1){

    if(level == 1){

      setTimeout(()=>{
        if(!this._blockmenu){

          this._blockmenu = false;
          menuTrigger.closeMenu();
        }
      },100)

      return;
    }
    setTimeout(()=>{
      if(this._blockmenu){
        this._blockmenu = false;
        menuTrigger.closeMenu();
      }
    },100)
  }

  isLogged(){
    return this.auth.isLogged();
  }

  getDashboard() {
    return this.auth.getDashboard();
  }


  ngOnInit(){
    this._susbsToDelete.push(this._headerService.observable().subscribe((hideImg : boolean)=> {
      this.toggleImage(hideImg);
    }));

    this._susbsToDelete.push(
      this._headerService.observableRegister().subscribe((data : any)=> {
        if(data){
          this.openRegister(null,false,null,data)
        }
      })
    )
  }

  ngOnDestroy(){
    this._susbsToDelete.forEach((s)=>{
      s.unsubscribe();
    })
  }

  search() {
    
    const arrive = moment(this.start.value)?.format(GLOBAL_FORMAT);
    const depart = moment(this.end.value)?.format(GLOBAL_FORMAT);
    this.filtersService.dates({start : arrive, end : depart})
    this._router.navigateByUrl(`/rent-rooms-dublin`);
    this.filtersService.emit();
  }

  toggleImage(hideImg : boolean){
    this.blockHeader = hideImg;
    this._is_active = hideImg;
    this.changes.detectChanges();
  }

  openLogin(event ?: any, request ? : any): void {
    if(event){
      event.stopPropagation();
      event.preventDefault();
    }
    this.wasOpen = true;
    const dialogRef = this.dialog.open(LoginComponent, {
      data: {request : request},
      width: '80%',
      maxWidth: 'unset',
      height: 'fit-content',
      maxHeight: 'unset',
      backdropClass: 'cdk-modal-login'
    });

    const DIALOG_REF = dialogRef.afterClosed().subscribe(result => {
      DIALOG_REF.unsubscribe();
      this.wasOpen = false;
      if(result){
        if(result.register)
          this.openRegister(undefined, false, null, request);
        else if(result.forgotPassword)
          this.openForgotPassword();
        else if(result.confirm_mode)
          this.openRegister(null,true,result.body,request);
        else if(result.logged){
          this.changes.detectChanges();
        }
      }
    });
  }

  openForgotPassword() : void {
    this.wasOpen = true;
    const dialogRef = this.dialog.open(ForgotPasswordComponent, {
      data: {},
      width: '80%',
      maxWidth: 'unset',
      height: 'fit-content',
      maxHeight: 'unset',
      backdropClass: 'cdk-modal-forgot-password'
    });

    const DIALOG_REF = dialogRef.afterClosed().subscribe(result => {
      DIALOG_REF.unsubscribe();
      this.wasOpen = false;

      if(result){
        this.openLogin();
      }
    });
  }

  openRegister(event?:any, confirm_mode : boolean = false, body : any = null,request : any = null): void {
    if(event){
      event.stopPropagation();
      event.preventDefault();
    }
    this.wasOpen = true;
    const dialogRef = this.dialog.open(RegisterComponent, {
      data: {confirm_mode: confirm_mode, body : body, request: request},
      width: '80%',
      maxWidth: 'unset',
      height: 'fit-content',
      maxHeight: 'unset',
      backdropClass: 'cdk-modal-register'
    });

    const DIALOG_REF = dialogRef.afterClosed().subscribe(result => {
      DIALOG_REF.unsubscribe();
      this.wasOpen = false;
      if(result){
        if( result.login) this.openLogin(null,result.request);
        else if(result.logged) this.changes.detectChanges();
      }
    });
  }

  getRoute(r:string){
    const rC = this.routes[r];
    const imgOut = this._headerService.isHiddenImage() ? null : (rC ? rC.image : this.default_image);

    if(!rC){
      return {image : imgOut};
    }

    const routeOut = structuredClone(rC);
    routeOut.image = imgOut;


    return routeOut;
  }

  getRoutes(){
    return Object.keys(this.routes);
  }

  isActive(): boolean {
    return this._is_active
  }

  calcTop(arg0: any): any {
    if(!arg0) return arg0;

    return `calc(${arg0} - 19vh)`
  }


  private toggleActivate () {
    if(this.wasOpen && this._is_active || this.blockHeader) return;
    const initialScrollPosition = window.scrollY || document.documentElement.scrollTop;
    if (initialScrollPosition > 80) {
      this._is_active = true;

    }else{
      this._is_active = false;
    }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: Event): void {
    this.toggleActivate();
  }

}
