
<mat-drawer-container style="z-index: 99;" dir="ltr" style="background: inherit;" >
    <mat-drawer [mode]="mode" (closed)="close()" 
    [ngStyle]="{
        'display' : firstmP ? 'none' : null
    }"
    style="max-width: 200px; border-radius: 0; background-color: white; position: fixed; top: 69px;" #drawer>
        <!-- <header style="padding: 0.8em; justify-content: space-between; align-items: center; display: flex; font-weight: 500; font-size: large; ">
            Options
            <button (click)="toggleMenu()" mat-icon-button>
                <mat-icon matIconSuffix>close</mat-icon>
            </button>
        </header> -->

        <div class="drawer-cont-section">
            @if(getDashboard().links && getDashboard().links.length){
                <section class="drawer-section">
                    @for(but of getDashboard().links ; track $index){
                        <a (click)="clickedOpt()" [routerLink]="'/dashboard/'+but.link" style="text-decoration: none; color: inherit;">
                            <button [ngStyle]="{'background-color': (but.link ? urlActive.includes(but.link) : urlActive.endsWith('dashboard')) ? '#d7d7d7' : ''}" mat-flat-button>
                                <mat-icon matIconPrefix>{{but.icon}}</mat-icon>
                                <span style="width: 135px; display: flex;">{{but.name}}</span>
                            </button>
                        </a>
                    }
        
                </section>
            }
            <section class="drawer-section">
              @for(but of getDashboard().userLinks ; track $index){
                  <a (click)="clickedOpt()" [routerLink]="'/dashboard/'+but.link" style="text-decoration: none; color: inherit;">
                      <button [ngStyle]="{'background-color':(but.link ? urlActive.includes(but.link) : urlActive.endsWith('dashboard')) ? '#d7d7d7' : ''}" mat-flat-button>
                          <mat-icon matIconPrefix>{{but.icon}}</mat-icon>
                          <span style="width: 135px; display: flex;">{{but.name}}</span>
                      </button>
                  </a>
              }
    
          </section>
            <section class="drawer-section">
                @for(but of getDashboard().buttons ; track $index){
                    <a (click)="clickedOpt()" [routerLink]="but?.link" style="text-decoration: none; color: inherit;">
                        <button [ngStyle]="{'background-color': urlActive.includes(but?.link ?? '-NULL-') ? '#d7d7d7' : ''}" (click)="but.click ? but.click() : null" mat-flat-button>
                            <mat-icon matIconPrefix>{{but.icon}}</mat-icon>
                            <span style="width: 135px; display: flex;">{{but.name}}</span>
                        </button>
                    </a>
                }
            </section>
        </div>
    </mat-drawer>
    <mat-drawer-content>
        <mat-drawer-container style="background-color: inherit;">
            <main style="min-height: 41.3dvh; margin: 1em;">
                <router-outlet></router-outlet>
            </main>
        </mat-drawer-container>
    </mat-drawer-content>

</mat-drawer-container>

